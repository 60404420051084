var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container results-container"},[_c('div',{staticClass:"section"},[_c('div',{staticClass:"row",attrs:{"id":"savedResultView"}},[_c('div',{staticClass:"col s10 offset-s1 center-align"},[_c('div',[_c('v-card-title',[_c('router-link',{attrs:{"to":{
                name: 'input',
              }}},[_vm._v("New Query")]),_c('v-spacer'),_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"append-icon":"mdi-magnify","label":"Enter Search Term Here","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"headers":_vm.headers,"items":_vm.desserts,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"search":_vm.search,"hide-default-footer":true},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.analysisIdNav",fn:function(ref){
              var item = ref.item;
return [(item.status != 'START_PROCESSING')?_c('div',[_c('router-link',{attrs:{"to":{
                    name: 'result',
                    params: { analysisId: item.analysisIdNav },
                  }}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-chart-bar")])],1)],1):_vm._e()]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [(item.status == 'START_PROCESSING')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v(" Processing ")])]}}],null,true)},[_c('span',[_vm._v("Processing")])]):(item.status == 'COMPLETED')?_c('v-tooltip',{attrs:{"top":"","color":"teal"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":"teal","text-color":"white"}},[_vm._v(" Completed ")])]}}],null,true)},[_c('span',[_vm._v("Completed")])]):(item.status == 'PENDING')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-chip',{staticClass:"ma-2"},[_vm._v(" Queued ")])]}}],null,true)},[_c('span',[_vm._v("Queued")])]):(item.status == 'ERRORED')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","text-color":"white"}},[_vm._v(" Error ")])]}}],null,true)},[_c('span',[_vm._v("Error")])]):_vm._e(),(item.status == 'PENDING')?_c('v-btn',{staticClass:"ms-6",on:{"click":function($event){return _vm.cancelAnalyse(item.analysisId)}}},[_vm._v("Cancel")]):_vm._e()]}}])}),_c('v-row',[_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"length":_vm.pageCountR},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"items-dropdown",attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"items":[5, 10, 15, 20, 25, 30],"label":"Items per page","type":"number"},model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}})],1)],1)],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }